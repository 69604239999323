<template>
    <div>
        <v-main fluid class="mt-n6">
            <div>
                <v-form ref="form"
                        v-model="valid"
                        class="w-100">

                    <v-container v-show="GetWhatToShow == 'view'" class="w-100 pa-8">
                        <kview form="hakedis"
                               :viewname="GetViewName"
                               @rowclicked="ViewClicked($event, 'view')"></kview>
                    </v-container>
                    <v-container v-show="GetWhatToShow != 'view'" fluid class="pa-8">
                        <v-btn text tile color="primary" @click="BackToView">
                            <v-icon left>mdi-arrow-left</v-icon>{{$t("AL_Back")}}
                        </v-btn>
                        <v-row>
                            <v-col class="pb-0 mb-0">
                                <dynoform header="Firmamız Hakedişleri"
                                          @change="UpdateFormField($event, 'formdata')"
                                          @changeGrid="UpdateExtData($event, $event.tableName)"
                                          @click="FormButtonClicked($event)"
                                          :attributes="GetFormAttributes"
                                          @SearchInputChanged="SearchInputChanged($event, 'formdata')"
                                          :tabname="Math.random().toString()"></dynoform>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-dialog v-model="actionBarWait"
                          persistent>
                    <v-card color="transparent"
                            dark class="py-5">
                        <v-card-text class="text-center py-5">
                            <v-progress-circular indeterminate
                                                 :size="50"
                                                 color="white"></v-progress-circular>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
            <v-footer class="grey lighten-3 pa-3" inset app padless>
                <v-progress-circular v-if="actionBarWait" :size="20" :width="2" rounded indeterminate color="primary" class="my-2 mr-2"></v-progress-circular>
                <v-toolbar-title v-if="actionBarWait" class="primary--text overline">{{$t("IM_YourTransactionIsInProgress")}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-show="GetWhatToShow != 'view'" v-for="(actionInfo, index) in GetFormAction"
                       :loading="actionBarWait"
                       @click="ApprovedAction(actionInfo)"
                       depressed
                       class="mx-2 px-10 primary darken-1 white--text font-weight-medium text-capitalize"
                       :class="index == GetFormAction.length - 1 ? 'primary' : 'secondary'"
                       :key="actionInfo.action">
                    {{ $t(actionInfo.label) }}
                </v-btn>
            </v-footer>
        </v-main>
    </div>
</template>

<style>
    .grey-lighten-4 {
        background-color: #f5f5f5;
    }

    .v-dialog {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
</style>

<script>

    import Utilities from '@/Utilities.js'
    import Vue from 'vue';
    import Vuex from 'vuex';
    Vue.use(Vuex);

    var sal_kalem_detay = {
        tableName: 'sal_kalem_detay',
        columns: {
            ad: {
                type: 'text',
                name: 'ad',
                label: 'FL_ItemName',
                edit: '',
                validateMin: 1,
            },
            talepmiktar: {
                type: 'text',
                name: 'talepmiktar',
                label: 'FL_OrderedAmount',
                edit: '',
                validateMin: 1,
            },
            doviz: {
                type: 'text',
                name: 'doviz',
                label: 'FL_Currency',
                edit: '',
            },
            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                miktar: { items: [], error: false, readonly: false, hidden: false },
                paracinsi: { items: [], error: false, readonly: false, hidden: false },
                alisfiyat: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'sal_kalem_detay_siparis'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };

    var Siparis_formInfo = {
        tabs: [
            { order: 1, header: 'HAKEDİŞ FORMU' },
        ],
        groups: [
            {
                tab: 1,
                order: 1,
                header: 'TH_FormInformation'
            },
            {
                tab: 1,
                order: 2,
                header: 'TH_ItemInformation'
            },
            {
                tab: 1,
                order: 3,
                header: 'Hakediş Bilgileri'
            },
        ],

        Columns: {
            id: {
                type: 'int',
                group: 1,
                sira: 10,
                name: 'id',
                label: 'FL_FormNo',
                edit: '',
                col: '6',
            },

            konu: {
                type: 'text',
                group: 1,
                sira: 15,
                name: 'konu',
                label: 'FL_Subject',
                edit: '',
                col: '6',
            },

            teslimtarihi: {
                type: 'hidden',
                group: 1,
                sira: 25,
                name: 'teslimtarihi',
                label: 'FL_DeliveryDate',
                edit: '',
                col: 6,
                options: []
            },
            miktarsiz: {
                type: 'hidden',
                name: 'miktarsiz',
            },

            talepad: {
                type: 'hidden',
                group: 2,
                sira: 2,
                name: 'talepad',
                label: 'FL_RequestOwner',
                edit: '',
                col: '6',
            },

            talepunite: {
                type: 'hidden',
                group: 2,
                sira: 10,
                name: 'talepunite',
                label: 'FL_UnitOfRequestOwner',
                edit: '',
                col: '6',
            },

            talepsube: {
                type: 'text',
                group: 1,
                sira: 15,
                name: 'talepsube',
                label: 'Hizmet Alınan Lokasyon',
                edit: '',
                col: '6',
            },
            hakedistutar: {
                type: 'number',
                group: 3,
                sira: 1,
                label: 'Hakediş Tutarı',
                edit: 'H',
                name: 'hakedistutar',
                width: 20,
                col: '12',
            },
            hakedisdosyasi: {
                type: 'file',
                group: 3,
                sira: 15,
                name: 'hakedisdosyasi',
                label: 'Hakediş Exceli',
                newFileLabel: 'Hakediş Dosyası Ekle',
                showFileLabel: 'Hakediş Dosyasını Görüntüle',
                edit: 'H',
                col: '12',
            },
            faturadosyasi: {
                type: 'file',
                group: 3,
                sira: 16,
                name: 'faturadosyasi',
                label: 'Fatura Dosyası',
                newFileLabel: 'Faturayı Ekle',
                showFileLabel: 'Faturayı Görüntüle',
                edit: 'F',
                col: '12',
            },
            faturano: {
                type: 'text',
                group: 3,
                sira: 17,
                name: 'faturano',
                label: 'Fatura No',
                edit: 'F',
                col: '6',
            },
            tedarikcinotlari: {
                type: 'textarea',
                group: 3,
                sira: 35,
                name: 'tedarikcinotlari',
                label: 'Tedarikçi Notları',
                edit: 'HF',
                col: '12',
            },
            tedarikcinotlarihistory: {
                type: 'textarea',
                group: 3,
                sira: 35,
                name: 'tedarikcinotlarihistory',
                label: 'Önceki Notlar',
                edit: '',
                col: '12',
            },
            sal_kalem_detay: {
                type: 'grid',
                group: 2,
                sira: 10,
                name: 'sal_kalem_detay',
                label: 'FL_OrderItems',
                edit: '',
                col: '12',
                showselect: true,
                buttons: [],
                dontCompare: true,
            },
            oonaynot: {
                type: 'hidden',
                group: 4,
                sira: 25,
                name: 'oonaynot',
                col: '12',
                edit: '',
                label: ''
            },
        },
        form: 'hakedis',
        formid: 0,
        viewName: 'benim',
        viewparameter: '',
        ignoreActions: true,
        files: ['hakedisdosyasi','faturadosyasi'],
        extdata: [sal_kalem_detay],
        actions: [],
        parameters: [],
        attributes: {}
    };

    import { mapGetters } from 'vuex';
    import dynoform from '@/components/DynoForm.vue';
    import grid from '@/components/grid.vue';
    import leftmenu from '@/components/leftmenu.vue';
    import kview from '@/components/view.vue';
    import axios from 'axios';
    import _ from 'lodash';
    import 'material-design-icons-iconfont/dist/material-design-icons.css';
    import { FormMixIn } from '@/MixIns/FormMixins';
    import store from '@/store';
    import moment from 'moment';

    import showdialog from '@/components/ShowDialog.vue';
    Vue.component('showdialog', showdialog);

    import { acosh } from 'core-js/core/number';

    export default {
        name: 'siparis',
        mixins: [FormMixIn],
        components: {
            dynoform,
            leftmenu,
            kview,
        },
        props: {
            //form: String,
            formid: [Number, String],
            view: String,
            drawer: { type: Boolean, default: true },
            message: Object
        },
        data: function ()
        {
            return {
                valid: false,
                showDamgaEkFile: '0',
                messages: { messageType: '', messageBody: '', infoBody: '', viewType: '' },
                viewName: '',
                username: '',
                form: '',
                formname: 'siparis',
                openallpanel: [0],
                currentform: 'siparis',
                aciklama: '',
                errors: []                
            };
        },
        methods: {
            FormLoaded: function ()
            {
                var currstate = this.GetState;
                this.$store.commit('SetFormActions', []);

                if (currstate == 'H')
                {
                    Siparis_formInfo.Columns.faturadosyasi.type = 'hidden'
                    Siparis_formInfo.Columns.faturano.type = 'hidden'
                }
                else
                {
                    Siparis_formInfo.Columns.faturadosyasi.type = 'file'
                    Siparis_formInfo.Columns.faturano.type = 'text'
                }

                if (currstate == 'H' && !this.actionBarWait)
                {
                    this.$store.commit('SetFormActions', [{ label: 'Hakedişi Gönder', action: 'Tamam', type: 'external' }]);
                }
                else if (currstate == 'F' && !this.actionBarWait)
                {
                    this.$store.commit('SetFormActions', [{ label: 'Fatura Hazırlandı', action: 'Tamam', type: 'external' }]);
                }
                var self = this;
                var payload = {};
                payload.action = "GetAddressData"
                payload.data = [{ "formid": this.GetFormId }];
                this.$store.dispatch("RunTime", payload)
                    .then(response => {
                        Utilities.log("Runtime  success result:", response);
                        self.$store.commit('SetFields', response.data.runTimeResult[0]);
                    })
                    .catch(err => {
                        Utilities.log("Runtime error result:", err);
                    });
            },


            ValidateForm: function (action) {
                this.ClearFieldErrors();
                this.errors = [];
                var result = "";

                //var formdata = this.GetFormData;

                //if (action.action == "PortalBilgi") {
                //    var err;
                //    if (!formdata.oonaynot) {
                //        err = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_Note') });
                //        this.errors.push(err);
                //    }
                //}
                //if (this.errors.length > 0) {
                //    result = this.errors;
                //}

                return result;
            },

            Selected: function ()
            {
                return;
            },

            NeedApprove: function (action)
            {
                var res;
                switch (action)
                {
                    case 'PortalGonderildi':
                        res = this.$root.$i18n.t('DM_OrderSentTransactionConfirm');
                        break;
                    default:
                        res = '';
                }
                return res;
            },
            FormButtonClicked(col)
            {
                var name = col.name;
            },

            RowClicked: function (rowdata, table)
            {
                return;
            },

            RowButtonClicked: function (payload, tableName)
            {
                return;
            },

            ChangeAttribute: function (attribute, tableName)
            {
                var payload = { ...attribute, tableName };
                Utilities.log("Grid Emit changeAttribute", payload);
                this.$store.commit('SetFieldAttributes', payload);
            },
        },
        mounted: function ()
        {
            this.openallpanel = [0];
            this.$store.commit('updateFormInfo', Siparis_formInfo);
            var form = Siparis_formInfo.form;
            var whatToShow = this.$route.name.replace(form, '');
            this.$store.commit('SetWhatToShow', { show: whatToShow });
            if (whatToShow == 'form') {
                var params = this.$route.params;
                if (params != undefined && params.id != undefined && Number(params.id) > 0) {
                    var formid = Number(params.id);

                    if (formid != undefined && formid != 0) {
                        this.$store.commit('SetFormAndFormId', {
                            form: form,
                            formid: formid
                        });
                        this.LoadFormData(formid);
                    }
                }
            }
            else if (whatToShow == 'yeni') {
                this.$store.commit('SetFormAndFormId', {
                    form: form,
                    formid: 0
                });
                this.LoadFormData(0);
            } else {
                this.$store.commit('SetWhatToShow', { show: whatToShow });
            }
            var self = this;
            this.$store.dispatch('GetUserInfo').then(userInfo => {
                self.username = userInfo.data.AdSoyad;
            });

            //Siparis_formInfo.Columns.kabuledildi.options = [
            //    { text: this.$root.$i18n.t('OP_Yes'), value: '1' },
            //    { text: this.$root.$i18n.t('OP_No'), value: '0' },
            //];
        },
        computed: {
            FormAttributes: function ()
            {
                return this.GetFormAttributes;
            },
            UserName()
            {
                return this.GetUserName;
            },
            FormLoadingStatus: {
                get: function ()
                {
                    return this.GetFormLoadingStatus;
                }
            },
            GetState: function ()
            {
                return this.$store.getters.GetFormState;
            },
        },
        watch: {
            FormLoadingStatus: {
                handler: function (val, oldVal)
                {
                    console.log('FormLoadingStatus changed');
                },
                deep: true
            },


            $route: function (nval, oval)
            {
                console.log('WATCH Route :', nval, ' form:', this.formname);
                console.log('WATCH Route this:', this.$route);
                var form = Siparis_formInfo.form;
                var whatToShow = this.$route.name.replace(form, '');
                this.$store.commit('SetWhatToShow', { show: whatToShow });
                if (whatToShow == 'form') {
                    var params = this.$route.params;
                    if (params != undefined && params.id != undefined && Number(params.id) > 0) {
                        var formid = Number(params.id);

                        if (formid != undefined && formid != 0) {
                            this.$store.commit('SetFormAndFormId', {
                                form: form,
                                formid: formid
                            });
                            this.LoadFormData(formid);
                        }
                    }
                }
                else if (whatToShow == 'yeni') {
                    this.$store.commit('SetFormAndFormId', {
                        form: form,
                        formid: 0
                    });
                    this.LoadFormData(0);
                } else {
                    this.$store.commit('SetWhatToShow', { show: whatToShow });
                }
            },
            
        }
    };
</script>
